




import { defineComponent, computed } from '@vue/composition-api';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';
import merge from 'lodash/merge';
import min from 'lodash/min';
import max from 'lodash/max';
import numeral from 'numeral';
import { formatData } from '../utils';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const apiData = props.data;

    const yearDept = apiData.year_dept;
    const filteredData = {};
    const years = Object.keys(yearDept);
    const dataLabels = [
      'amount',
      'dept_name',
      'project_cnt',
      'project_percent',
    ];

    const dataSet = [];
    const projectList = Object.keys(yearDept).map(year => yearDept[year].map(item => item.dept_name));
    const uniqueProjects = uniq(flatten(projectList));

    forEach(uniqueProjects, (uniqueProject, index) => {
      dataSet.push({
        name: uniqueProject,
        data: [],
      });
    });

    forEach(years, year => {
      forEach(dataSet, (data, index) => {
        const project = yearDept[year].find(item => item.dept_name === data.name);
        if (project) {
          data.data.push(project.amount);
        } else {
          data.data.push(null);
        }
      });
    });

    const propsData = {
      id: 'GovernmentProjectYearDept',
      title: 'มูลค่าโครงการที่ชนะการเสนอราคาทั้งหมดต่อปี',
      chartConfigs: {
        series: dataSet,
        chart: {
          stacked: true,
        },
        xaxis: {
          title: {
            text: 'ปี (ค.ศ)',
          },
          categories: Object.keys(yearDept),
        },
        yaxis: {
          min: 0,
          title: {
            text: 'มูลค่า (บาท)',
          },
          labels: {
            formatter: value => formatData(value, '0,0a', null, '฿ '),
          },
        },
        tooltip: {
          y: {
            formatter: value => formatData(value, '0,0', null, '฿ '),
          },
        },
      },
    };
    return {
      propsData,
    };
  },
});
